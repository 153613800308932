@font-face {
  font-family: "Barlow-SemiBold";
  src: url(../fonts/Barlow-SemiBold.otf);
}
@font-face {
  font-family: "Barlow-Regular";
  src: url(../fonts/Barlow-Regular.otf);
}
@font-face {
  font-family: "Barlow-Medium";
  src: url(../fonts/Barlow-Medium.otf);
}
@font-face {
  font-family: "Barlow-Light";
  src: url(../fonts/Barlow-Light.otf);
}
@font-face {
  font-family: "Barlow-Bold";
  src: url(../fonts/Barlow-Bold.otf);
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Barlow-Light";
  overflow-x: hidden;
  position: relative;
}
h1 {
  font-size: 30px;
}
.navbar {
  background-color: transparent;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: #35c42c;
  font-family: "Barlow-SemiBold";
}
.nav-link:hover,
.nav-link:focus {
  color: #35c42c;
}
.nav-link {
  color: #fff;
  font-family: "Barlow-Regular";
  cursor: pointer;
}
.banner-premium {
  margin-top: -4px;
}
.title-green {
  font-family: "Barlow-Medium";
  color: #35c42c;
  font-size: 45px;
}
.title-white {
  font-family: "Barlow-Medium";
  color: #fff;
  font-size: 45px;
}
.text-cont {
  text-align: justify;
  font-family: "Barlow-Light";
  font-size: 16px;
}

.brg-img {
  background-image: url(../image/nosotros-mixticowhite.png);
  background-size: contain;
  background-position: 100%;
  background-color: #fff;
  background-repeat: no-repeat;
}
.section-img img {
  border-radius: 20px;
}
.style-lg {
  background: #e7c500;
  border-radius: 7px;
  text-align: center;
  color: #fff;
  font-family: "Barlow-Medium";
  font-size: 18px;
}
.brg-linea {
  background-image: url(../image/brg-linealyellow.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 84%;
}
.card-product {
  width: 300px;
}
.card-header {
  border: 0px;
  font-size: 18px;
}
.card-footer {
  background-color: #dee2e6;
}
.card-footer p {
  color: #000;
  font-family: "Barlow-Light";
  font-size: 16px;
}
.card-text {
  color: #737373;
}

.card-event {
  cursor: pointer;
}

.bg-card {
  background-color: #dee2e6;
  color: #000;
  font-family: "Barlow-Medium";
  border-bottom: 0px solid #35c42c;
}
.btn-primary {
  font-family: "Barlow-Medium";
  background-color: #35c42c;
  border: 0px;
  border-radius: 17px;
  color: #ffff;
  text-decoration: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 30px;
  padding-right: 30px;
}
.btn-primary:hover {
  background-color: #fff;
  border: 1px solid #35c42c;
  color: #35c42c;
}
.bg-product {
  background-image: url(../image/section-productos-ft.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.after::after {
  content: "";
  background-color: #e7c500;
  width: 131px;
  height: 1px;
  display: block;
  margin: 0 auto;
}
.carousel {
  padding-bottom: 53px;
}
.page-link {
  font-family: "Barlow-Medium";
  color: #16703c;
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  background-color: #16703c;
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: #fff;
  background-color: #16703c;
  outline: 0;
  box-shadow: none;
}
.page-link.active,
.active > .page-link {
  color: #fff;
  background-color: #16703c;
  border: 1px solid #16703c;
}
.brg-events {
  background-image: url(../image/section-eventos.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}
.text-style ul {
  font-size: 21px;
  line-height: 43px;
  color: #000;
}
.brg-nproduct {
  color: #fff;
  background-color: #16703c;
  background-image: url(../image/brg-nproductwhite.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
.brg-contacto {
  background-image: url(../image/section-contacto.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.brg-form {
  background-color: rgba(89, 111, 91, 0.2);
  border-radius: 15px;
  color: #000;
}
.style-enc {
  color: #86bd5f;
  font-family: "Barlow-SemiBold";
}
.text-cont {
  color: #000;
  font-family: "Barlow-Light";
}
.style-redes li {
  list-style: none;
  font-size: 15px;
}
.style-redes li {
  display: inline-block;
  font-size: 17px;
  color: #d9d9d9;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 300;
}

.style-redes a {
  color: #fff;
  text-decoration: none;
}
.style-redes a:hover {
  color: #fff;
}

.text-color {
  color: #d9d9d9;
}
footer {
  background-color: #000;
  border-top: 1px solid #35c42c;
}

.modal-title {
  color: #737373;
  font-family: "Barlow-Medium";
}
.title-product {
  border-radius: 2px;
  text-align: center;
  font-family: "Barlow-Medium";
  color: #fff;
}
.slick-prev {
  display: none !important;
  left: 30px !important;
}
.slick-next {
  display: none !important;
  right: 30px !important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #fff !important;
}
.slick-dots li button:before {
  color: #fff !important;
  font-size: 10px !important;
}

.slick-thumb {
  bottom: -45px !important;
}

.slick-thumb li {
  width: 60px !important;
  height: 45px !important;
}

.swal2-styled.swal2-confirm {
  background-color: #35c42c !important;
}
.swal2-styled.swal2-confirm:focus {
  border: 1px solid #3e627c;
  box-shadow: none;
}
@media (max-width: 768px) {
  #navMenu.navbar-shrink {
    padding-top: 0;
    background-color: #000;
    border-bottom: 1px solid #35c42c;
    padding-bottom: 15px;
    color: #35c42c;
    opacity: 0.9;
  }
  .title-green {
    font-size: 30px;
  }
  .card-product {
    width: 100%;
  }
  .brg-nproduct {
    background-color: #fff;
    background-size: contain;
    background-position: top;
  }
  .navbar {
    background-color: #000;
  }
  .fixed-top {
    position: relative;
  }
  .brg-linea {
    background-image: none;
  }
}
@media (min-width: 992px) {
  #navMenu.navbar-shrink {
    padding-top: 0;
    background-color: #000;
    border-bottom: 1px solid #35c42c;
    padding-bottom: 15px;
    color: #35c42c;
    opacity: 0.9;
  }
}
@media (max-width: 600px) {
  .title-green {
    font-size: 30px;
  }
  .card-product {
    width: 100%;
  }
  .brg-nproduct {
    background-color: #fff;
    background-size: contain;
    background-position: top;
  }
  .navbar {
    background-color: #000;
  }
  .fixed-top {
    position: relative;
  }
  .brg-linea {
    background-image: none;
  }
}
